<template>
  <div>
    <p class="text-2xl mb-6">Liste des Projets</p>
    <v-col cols="12" class="d-flex flex-row-reverse">
      <v-btn color="primary" link :to="{ name: 'rmra-projects-create' }">
        <v-icon class="mr-3">{{ icons.mdiPlusBox }}</v-icon
        >Créer un nouveau projet</v-btn
      >
    </v-col>
    <v-col cols="12"> <projects-table></projects-table> </v-col>
  </div>
</template>

<script>
import { mdiPlusBox } from "@mdi/js";
import ProjectsTable from "@/components/rmr-analytics/tables/ProjectsTable.vue";

export default {
  name: "ProjectsList",
  components: {
    ProjectsTable,
  },
  created() {},
  data() {
    return {
      icons: {
        mdiPlusBox,
      },
    };
  },
  methods: {},
  watch: {},
};
</script>
<style scoped></style>
